<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <div
          class="
            d-flex
            flex-wrap
            align-items-center
            justify-content-between
            mb-3
          "
        >
          <div class="d-flex align-items-center justify-content-between">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb p-0 mb-0">
                <li class="breadcrumb-item">
                  <router-link
                    :to="{ path: '/customerview/' + this.number }"
                    class=""
                    >Customer View</router-link
                  >
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  Edit Car
                </li>
              </ol>
            </nav>
          </div>
          <div
            @click="handleBackBtn"
            class="
              btn btn-secondary btn-sm
              d-flex
              align-items-center
              justify-content-between
            "
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z"
                clip-rule="evenodd"
              />
            </svg>
            <span class="ml-2">Back</span>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-12">
                <form class="row g-3 date-icon-set-modal">
                  <div class="col-md-4 mb-3">
                    <label
                      for="Text1"
                      class="form-label font-weight-bold text-muted"
                      >Make</label
                    >
                    <input
                      type="text"
                      disabled
                      class="form-control"
                      id="Text1"
                      v-model="vehicleMakeName"
                    />
                  </div>

                  <div class="col-md-4 mb-3">
                    <label
                      for="Text2"
                      class="form-label font-weight-bold text-muted"
                      >Model</label
                    >
                    <input
                      type="text"
                      disabled
                      class="form-control"
                      id="Text2"
                      v-model="vehicleModelName"
                    />
                  </div>

                  <div class="col-md-4 mb-3">
                    <label
                      for="Text3"
                      class="form-label font-weight-bold text-muted"
                      >Year *</label
                    >
                    <!-- <input type="text" class="form-control" id="Text3" placeholder="Enter Year" v-model="year"> -->
                    <vue-single-select
                      v-model="year"
                      :options="years"
                      :getOptionDescription="getCustomDescriptionYears"
                    >
                    </vue-single-select>
                    <label
                      v-if="invalidEmptyYear"
                      for="Text3"
                      class="
                        text-uppercase text-danger
                        col-form-label-sm
                        mt-1
                        ml-1
                      "
                      >Car year cannot be empty</label
                    >
                  </div>
                  <div class="col-md-4 mb-3">
                    <label for="Text4" class="form-label text-muted"
                      >Classification</label
                    >
                    <input
                      type="text"
                      disabled
                      class="form-control"
                      id="Text4"
                      placeholder="Enter Classification"
                      v-model="classification"
                    />
                  </div>
                  <div class="col-md-4 mb-3">
                    <label for="Text5" class="form-label text-muted"
                      >Plate No</label
                    >
                    <input
                      type="text"
                      class="form-control"
                      id="Text5"
                      placeholder="Enter Plate Number"
                      v-model="licensePlate"
                    />
                  </div>
                  <div class="col-md-4 mb-3">
                    <label for="Text6" class="form-label text-muted"
                      >Vin No</label
                    >
                    <input
                      type="text"
                      class="form-control"
                      id="Text6"
                      placeholder="Enter Vin"
                      v-model="vin"
                    />
                  </div>
                  <div class="col-md-4 mb-3">
                    <label for="Text7" class="form-label text-muted"
                      >Mileage</label
                    >
                    <input
                      type="text"
                      class="form-control"
                      id="Text7"
                      placeholder="Enter Mileage"
                      v-model="mileage"
                    />
                  </div>
                  <div class="col-md-4 mb-3">
                    <label for="Text8" class="form-label text-muted"
                      >Next Service Mileage</label
                    >
                    <input
                      type="text"
                      class="form-control"
                      id="Text8"
                      placeholder="Enter Next Service Mileage"
                      v-model="nextServiceMileage"
                    />
                  </div>
                  <div class="col-md-4 mb-3">
                    <label for="Text9" class="form-label text-muted"
                      >Next Service Due Date</label
                    >
                    <!-- <input type="text" class="form-control" id="Text9" placeholder="Enter Next Service Due Date"> -->
                    <b-form-datepicker
                      id="datepicker-next-service-date"
                      v-model="nextServiceDue"
                      size="sm"
                      :min="min"
                      menu-class="w-100"
                      calendar-width="100%"
                      class="mb-2"
                      placeholder="Select Next Service Date"
                      @context="onContext"
                      :date-format-options="{
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                      }"
                    ></b-form-datepicker>
                  </div>
                  <div class="col-md-4 mb-3">
                    <label for="Text9" class="form-label text-muted"
                      >Registration Renewal Date</label
                    >
                    <!-- <input type="text" class="form-control" id="Text9" placeholder="Enter Next Service Due Date"> -->
                    <b-form-datepicker
                      id="datepicker-registration-renewal-date"
                      v-model="registrationRenewalDate"
                      size="sm"
                      menu-class="w-100"
                      calendar-width="100%"
                      class="mb-2"
                      placeholder="Select Next Service Date"
                      @context="onContextRegistrationRenewalDate"
                      :date-format-options="{
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                      }"
                    ></b-form-datepicker>
                  </div>
                   <div class="col-md-4 mb-3">
                    <label for="Text9" class="form-label text-muted"
                      >Insurance Renewal Date</label
                    >
                    <!-- <input type="text" class="form-control" id="Text9" placeholder="Enter Next Service Due Date"> -->
                    <b-form-datepicker
                      id="datepicker-insurance-renewal-date"
                      v-model="insuranceRenewalDate"
                      size="sm"
                      menu-class="w-100"
                      calendar-width="100%"
                      class="mb-2"
                      placeholder="Select Next Service Date"
                      @context="onContextInsuranceRenewalDate"
                      :date-format-options="{
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                      }"
                    ></b-form-datepicker>
                  </div>
                </form>
                <button class="btn btn-success btn-sm" id="edit-car" @click="UpdateUserCar()">
                  Update Car
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { GET_CAR_DETAILS } from "../../../graphql/query";
import { UPDATE_CAR } from "../../../graphql/mutation";

export default {
  name: "CarEdit",
  props: ["number", "userCarId"],
  data() {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const minDate = new Date(today);
    return {
      userCar: [],
      vehicleMakeName: "",
      vehicleModelName: "",
      min: minDate,
      nextServiceDue: null,
      year: null,
      licensePlate: "",
      mileage: null,
      nextServiceMileage: null,
      vin: null,
      invalidEmptyYear: false,
      errors: [],
      classification: "",
      years: [],
      registrationRenewalDate: null,
      insuranceRenewalDate: null,
    };
  },
  beforeMount() {
    this.getUserCarDetails();
    // alert(this.number);
  },
  methods: {
    getCustomDescriptionYears(opt) {
      return opt;
    },
    getUserCarDetails: function () {
      this.$apollo
        .query({
          query: GET_CAR_DETAILS,
          variables: {
            id: this.userCarId,
          },
        })
        .then((data) => {
          this.userCar = data.data.userCar;
          this.vehicleMakeName = data.data.userCar.model.make.name;
          this.vehicleModelName = data.data.userCar.model.name;
          this.year = data.data.userCar.year;
          this.classification = data.data.userCar.classification;
          this.licensePlate = data.data.userCar.licensePlate;
          this.vin = data.data.userCar.vin;
          this.mileage = data.data.userCar.mileage;
          this.nextServiceMileage = data.data.userCar.nextServiceMileage;
          this.nextServiceDue = data.data.userCar.nextServiceDue;
          this.years = data.data.userCar.model.years;
          this.registrationRenewalDate = data.data.userCar.registrationRenewalAt;
          this.insuranceRenewalDate = data.data.userCar.insuranceRenewalAt;
        });
    },
    // handleYear(){

    // },
    onContext(ctx) {
      this.nextServiceDue = ctx.selectedYMD;
    },

    onContextRegistrationRenewalDate(ctx) {
      this.registrationRenewalDate = ctx.selectedYMD;
    },

    onContextInsuranceRenewalDate(ctx) {
      this.insuranceRenewalDate = ctx.selectedYMD;
    },
    UpdateUserCar() {
      if (this.nextServiceDue === "") {
        this.nextServiceDue = null;
      } else if (this.year === null) {
        return (this.invalidEmptyYear = true);
      } else {
        document.getElementById("edit-car").disabled = true;
        this.$apollo
          .mutate({
            mutation: UPDATE_CAR,
            variables: {
              id: this.userCarId,
              year: this.year,
              vin: this.vin,
              licensePlate: this.licensePlate,
              mileage: this.mileage,
              nextServiceMileage: this.nextServiceMileage,
              nextServiceDue: this.nextServiceDue,
               registrationRenewalAt:this.registrationRenewalDate,
              insuranceRenewalAt:this.insuranceRenewalDate
            },
          })
          .then(() => {
            document.getElementById("edit-car").disabled = false;
            this.$router.go(-1);
          });
      }
    },
    handleBackBtn() {
      this.$router.go(-1);
    },
  },
};
</script>
<style>
.b-toast-success.b-toast-solid .toast.toast-box,
.b-toast-danger.b-toast-solid .toast.toast-box {
  padding: 10px;
  border-radius: 3px;
}
</style>
