import gql from 'graphql-tag'

export const GET_CUSTOMERS = gql`

query getUsers($first: Int, $search: String, $after: String, $before:String, $last:Int, $direction: OrderDirection!, $field: UserSortField!, $gte: Date, $lte: Date, $country: CountryEnum, $referrerType: CustomReferrerEnum, $isStaff: Boolean, $isActive: Boolean){ 
    bwsDashboardUsers(
        first: $first
        after: $after
        before: $before
        last: $last
        filter: { search: $search, country: $country, dateJoined: {gte: $gte, lte: $lte}, referrerType: $referrerType, isActive: $isActive, isStaff: $isStaff }
        sortBy: {direction: $direction,  field: $field}) 
        {
        pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
        }
        edges {
        node {
            id
            dateJoined
            firstName
            lastName
            email
            phone
        }
        cursor
        }
    }
  }
`

export const GET_CUSTOMERS_DETAILS = gql`
  query user($id: ID!) {
    bwsDashboardUser(id: $id) {
        profile {
          firstName
          lastName
          phone
          email
          dateJoined
        }
        cars {
          model {
            name
            make {
              name
            }
          }
          id
          classification
          licensePlate
        }
      }
    
  }
`

export const GET_CAR_MAKE = gql`
query {
    vehicleMakes {
      id
      isPopular
      logo
      name
    }
  }
`

export const GET_CAR_MODEL = gql`
query getModels($makeId: ID!){
    vehicleModels(makeId: $makeId) {
      id
      name
      years
      image
      classification
    }
  }
`

export const GET_CAR_DETAILS = gql`
query getUserCar($id: ID!) {
  userCar(id: $id) {
    id
    classification
    createdAt
    licensePlate
    model {
      make {
        name
      }
      name
      image
      years
    }
    vin
    year
    mileage
    mileageUpdatedAt
    nextServiceMileage
    nextServiceDue
    registrationRenewalAt
    insuranceRenewalAt
  }
}
`

export const GET_PAYMENT_METHODS = gql`
query bwsDashboardPaymentMethods($first: Int, $search: String){
  bwsDashboardPaymentMethods(first: $first, filter: {search: $search}) {
    totalCount
    pageInfo {
      startCursor
      endCursor
      hasNextPage
    }
    edges {
      cursor
      node {
        id
        name
        rank
        type
        currency
        requiresRoundingUp
      }
    }
  }
}`


export const PAYMENT_METHODS = gql`
query paymentMethods($first: Int, $search: String){
  paymentMethods(first: $first, filter: {search: $search}) {
    totalCount
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
    edges {
      cursor
      node {
        id
        name
        rank
        type
        currency
        requiresRoundingUp
      }
    }
  }
}
`

export const GET_CUSTOMERS_CARS = gql`
query getUserCars($userId: ID!,$first: Int,$last:Int,$before:String,$after:String,$search:String){
  bwsDashboarduserCars(first: $first, last:$last, after: $after,
before: $before, userId:$userId filter: { search: $search })
{
totalCount
pageInfo {
hasNextPage
hasPreviousPage
startCursor
endCursor
}
edges{
node{
id
model{
make{
name
}
name
}
licensePlate
classification
}
cursor
}
}
}
`

export const GET_BUILDING_LIST = gql`
  query BuildingList( $first: Int, $search: String) {
    bwsDashboardBuildings(first: $first, filter: {search: $search}){
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
        startCursor
      }
      edges {
        node {
          id
          isArea
          company{
            legalName
            registeredAddress
            contactPerson{
              firstName
              lastName
              phone
            }
            taxRate
          }
          name
          location{
            lat
            lng
          }
          address
          contactPerson{
            firstName
            lastName
            phone
          }
          shortCode
          slots{
            id
            weekDay
            fromTime
            toTime
          }
        }
      }
    }
  }
`


export const GET_PACKAGE_LIST = gql`
query PackageList( $carId: ID!, $buildingId: ID!){
  bwsDashboardPackages(carId: $carId, buildingId:$buildingId){
    id
    type{
      id
      name
      slug
      rank
      description
      weeklyWashes
    }
    intervalType
    numIntervals
    finalTotals{
      tax{
        amount
        currency
      }
      totalExclTax{
        amount
        currency
      }
      totalInclTax{
        amount
        currency
      }
    }
  }
}
`

export const BWS_DASHBOARD_WASH_SUBSCRIPTIONS = gql`
query bwsDashboardSubscriptions(
  $first: Int, 
  $after: String, 
  $before:String, 
  $last:Int, 
  $building: ID,
  $status: WashSubscriptionStatusEnum, 
  $weekDay: WeekDayEnum, 
  $gte: Date, $lte: Date, 
  $currency: CurrencyEnum, 
  $paymentMethod: ID,
  $updated_gte : Date, $updated_lte: Date,
  $search: String,
  ){
    bwsDashboardSubscriptions(
      first: $first
      after: $after
      before: $before
      last: $last 
      filter: { building: $building, status: $status, weekDay: $weekDay, currency: $currency, paymentMethod: $paymentMethod, search : $search, activatedAt: {gte: $gte, lte: $lte}, updatedAt: {gte: $updated_gte, lte: $updated_lte} }
    ){
      pageInfo{
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
      edges{
        cursor
        node{
          id
          scheduledSlots{
            weekDay
            fromTime
            toTime
            id
          }
          comments{
            comment
            createdAt
            createdBy{
              firstName
              lastName
            }
          }
          building{
            id
            company{
              legalName
              registeredAddress
              taxRate
            }
            name
            isArea
            location{
              lat
              lng
            }
            address
            contactPerson{
              firstName
              lastName
              phone
              email
            }
            shortCode
          }
          package{
            id
            type{
              name
              id
              slug
              weeklyWashes
              isActive
              rank
              description
            }
            intervalType
            numIntervals
          }
          customer{
            firstName
            lastName
            email
            phone
          }
          car{
            id
            model{
              id
              name
              make{
                name
                id
              }
            }
            year
            licensePlate
            vin
          }
          discount{
            amount
            currency
          }
          totalBeforeDiscount{
            amount
            currency
          }
          total{
            amount
            currency
          }
          tax{
            amount
            currency
          }        
          activatedAt
          pausedAt
          createdAt
          updatedAt
          validUpto
          unpausedAt
          paymentUrl
          completedAt
          cancelledAt
          lastReleaseAt
          nextReleaseAt
          status
          activationUrl
        }
      }
    }
  }
`

export const BWS_DASHBOARD_WASH_SUBSCRIPTION = gql`
query bwsDashboardSubscription($id: ID!){
  bwsDashboardSubscription(id: $id){
    id
    notes
    assignedTo{
      firstName
      lastName
    }
    parkingSlot
    scheduledSlots{
      weekDay
      fromTime
      toTime
      id
    }
    comments{
      comment
      createdAt
      createdBy{
        firstName
        lastName
      }
    }
    building{
      id
      company{
        legalName
        taxRate
        registeredAddress
      }
      name
      isArea
      location{
        lat
        lng
      }
      address
      contactPerson{
        firstName
        lastName
        phone
        email
      }
      shortCode
    }
    paymentMethod {
      name
      type
      id
    }
    paymentSource {
      isOnline
      invoice {
        number
        isPaid
        paymentLink
        status
        paymentDetails
      }
    }
    package{
      id
      type{
        name
        id
        slug
        weeklyWashes
        isActive
        rank
        description
      }
      intervalType
      numIntervals
    }
    createdBy{
      firstName
      lastName
      email
      phone
    }
    customer{
      firstName
      lastName
      email
      phone
    }
    car{
      id
      model{
        id
        name
        make{
          name
          id
        }
      }
      year
      licensePlate
      vin
    }
    discount{
      amount
      currency
    }
    totalBeforeDiscount{
      amount
      currency
    }
    total{
      amount
      currency
    }
    tax{
      amount
      currency
    }        
    activatedAt
    pausedAt
    createdAt
    updatedAt
    validUpto
    unpausedAt
    paymentUrl
    completedAt
    cancelledAt
    lastReleaseAt
    nextReleaseAt
    status
    activationUrl
    discountName
  }
}`

export const GET_BWS_DASHBOARD_WASHERS  = gql`
query bwsDashboardWashers($first:Int, $search: String){
  bwsDashboardWashers(first: $first, filter: {search: $search}){
    totalCount
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      node {
        id
        firstName
        lastName
        phone
        email
      }
    }
  }
}`

export const BWS_DASHBOARD_SUBSCRIPTION_SLOTS = gql`
query bwsDashboardSubscriptionSlots($id: ID!){
  bwsDashboardSubscriptionSlots(id: $id){
    weekDay
    fromTime
    toTime
    id
  }
}
`

export const GET_WASH_SUBS_AVAILABLE_VOUCHERS = gql`
query washGetAvailableVouchers($buildingId:ID!, $packageId:ID!){
  washGetAvailableVouchers(buildingId:$buildingId, packageId:$packageId){
    code
    isApplicable
    reason
    name
    discount{
      amount
      currency
    }
    trialPeriod
    totalAfterDiscount{
      amount
      currency
    }
    totalBeforeDiscount{
      amount
      currency
    }
    finalTotalExclTax{
      amount
      currency
    }
    finalTotalInclTax{
      amount
      currency
    }
    finalTax{
      amount
      currency
    }
  }
}
`

export const CHECK__WASH_SUBS_VOUCHERS = gql`
query washCheckVoucher($buildingId:ID!, $packageId:ID!, $voucherCode:String!){
  washCheckVoucher(buildingId:$buildingId, packageId:$packageId, voucherCode:$voucherCode){
    code
    isApplicable
    reason
    name
    discount{
      amount
      currency
    }
    trialPeriod
    totalAfterDiscount{
      amount
      currency
    }
    totalBeforeDiscount{
      amount
      currency
    }
    finalTotalExclTax{
      amount
      currency
    }
    finalTotalInclTax{
      amount
      currency
    }
    finalTax{
      amount
      currency
    }
  }
}`